import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import store from '@/store'
class OpenUploadCenter extends Plugin {
  init() {
    const editor = this.editor;

    editor.ui.componentFactory.add( 'openUploadCenter', locale => {
      const view = new ButtonView( locale );

      view.set( {
                  label: 'Open Image Explorer',
                  icon: imageIcon,
                  tooltip: true
                } );

      view.on( 'execute', () => {
        store.commit('SET_IS_OPEN',true)
      } );

      return view;
    } );
  }
}

export default OpenUploadCenter;
