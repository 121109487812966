<template>
 <div>
   <ckeditor
       :editor="editor"
       v-model="editorData"
       :config="editorConfig"
       :rules="isRequired"
       tagName="textarea"
       name="content"
   >
   </ckeditor>

     <el-dialog
         @close="closeUploadCenter"
         :destroy-on-close="true"
         v-if="showUploadCenter"
         title="مركز الرفع"
         v-model="showUploadCenter"
     >
       <UploadCenter
           :is-single-upload="false"
           @save="handleAddPhotos"
       />
     </el-dialog>
 </div>

</template>

<script>
import UploadCenter from "@/components/uploadCenter";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
//import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Clipboard from "@ckeditor/ckeditor5-clipboard/src/clipboard";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import OpenUploadCenter from "@/components/editorPlugins/openUploadCenter";
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import store from "@/store";
import CKEditor from "@ckeditor/ckeditor5-vue";
export default {
  name: "CustomCKEditor",
  props:{
    content : {
      type: String,
      default: "<div></div>"
    }
  },
  components: {
    UploadCenter,
    ckeditor: CKEditor.component
  },
  data()
  {
    return {
      selectedPhotos: [],
      editor      : ClassicEditor,
      editorData  :  this.content,
      editorConfig: {
        plugins     : [
          Image,
          ImageToolbar, ImageCaption, ImageStyle, ImageResize,
          GeneralHtmlSupport,
          EssentialsPlugin,
          Heading,
          Font,
          BoldPlugin,
          LinkPlugin,
          Underline,
          Strikethrough,
          ItalicPlugin,
          Table,
          TableToolbar,
          Alignment,
          Indent,
          IndentBlock,
          Clipboard,
          PasteFromOffice,
          ParagraphPlugin,
          Highlight,
          HorizontalLine,
          ListStyle,
          MediaEmbed,
          RemoveFormat,
          BlockQuote
        ],
        extraPlugins: [OpenUploadCenter],
        language    : "ar",
        mediaEmbed  : {
          previewsInData: true,
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: false,
              styles: true
            }
          ]
        },
        toolbar     : {
          shouldNotGroupWhenFull: true,
          items                 : [
            'heading',
            '|',
            'fontSize', 'fontFamily', '|',
            'alignment', '|',
            'fontColor', 'fontBackgroundColor', '|',
            'bold', 'italic',
            'underline', 'strikethrough',
            '|',
            'link',
            'blockQuote',
            '|',
            'insertTable',
            'openUploadCenter',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            //'linkImage',
            'mediaEmbed',
            'outdent', 'indent',
            'bulletedList', 'numberedList',
            'highlight',
            'horizontalLine',
            'removeFormat',
            'undo',
            'redo',
          ],
        },
        image       : {
          toolbar: ['imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', 'imageStyle:side'],
          styles : ['full', 'alignLeft', 'alignCenter', 'alignRight', 'side']
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: 'manual',
              label: 'فتح في نافذة جديدة',
              attributes: {
                target: '_blank',
              }
            },

            toggleNoFollow: {
              mode: 'manual',
              label: 'سمة NoFollow',
              attributes: {

                rel: 'nofollow'
              }
            },
          }
        },
        heading     : {
          options: [
            {
              model: "paragraph",
              title: "فقرة",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view : "h1",
              title: "العنوان 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view : "h2",
              title: "العنوان 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view : "h3",
              title: "العنوان 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view : "h4",
              title: "العنوان 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view : "h5",
              title: "العنوان 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view : "h6",
              title: "العنوان 6",
              class: "ck-heading_heading6",
            },

          ],
        },
        table       : {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
        },
        alignment   : {
          options: ['left', 'right', 'center', 'justify']
        },

      },
    }
  },
  watch: {
    editorData(content) {
        this.$emit("updateContent", content);
    }
  },
  mounted() {
    console.log('editor mount')
  },
  computed: {
    showUploadCenter()
    {
      return store.getters.isUploadCenterOpen
    },
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL;
    },
  },
  methods: {
    isRequired(value) {
      return value ? true : 'This field is required';
    },
    closeUploadCenter()
    {
      store.commit('SET_IS_OPEN', false)
      this.isSingleUpload = false;
    },
    handleAddPhotos(photos)
    {
      this.selectedPhotos = photos;
      photos.forEach(photo => {
        if(photo.type === 'video'){
          this.editorData += `<video v-if="file.type === 'video'" class="preview-image w-100" controls>
            <source src="${this.APP_URL + photo.url}" type="video/mp4">
            Your browser does not support the video tag.
        </video>`
        }else if(photo.url.indexOf('.mp3') !== -1){
          this.editorData += `<audio class="mx-auto" controls>
          <source src="${this.APP_URL + photo.url}" type="audio/mpeg">
          Your browser does not support the audio tag.
        </audio>`
        } else{
          this.editorData += `<img alt="" src="${this.APP_URL + photo.url}" />`
        }
      })
        this.closeUploadCenter();
    },
  }
}
</script>

<style>
.ck-editor__editable_inline {
  max-height: 450px;
  min-height: 400px;
  overflow-y: auto;
}


</style>
